<!-- 没有轨迹的原因弹窗 -->
<template>
    <el-dialog
        top="2vh"
        :show-close="false"
        :modal="false"
        :header="null"
        :visible="visible"
        @close="close"
        custom-class="service-agreement-dialog"
        width="600px"
    >
        <div class="agreement-content">
            <div
                class="title"
                style="font-size: 24px; font-weight: bold; margin-bottom: 40px"
            >
                查不到货车位置的可能原因
            </div>
            <div class="sub-title text">查不​到货车位置？</div>
            <div class="text">1.请您确认您的车辆是否是黄牌货车。</div>
            <div class="text">
                2.请您确认车辆是否是入网车辆。入网车辆是指，黄牌货车出厂上路必须安装车机入网。
            </div>
            <div class="text">
                3.请确认您的车牌号是否输入正确​货车位置不准确？
            </div>
            <div class="sub-title text">延迟？</div>
            <div class="text">​1.请确认车机流量卡是否停机欠费。</div>
            <div class="text">2.请确认司机是否拔掉车机。</div>
            <div class="text">3.请确认货车上的车机是否正常。</div>
            <div class="text">4.请确认是否有信号？</div>
            <div class="text">5.请确认车辆是否被卖出。</div>
            <div class="text">
                6.可在货车定位管家首页，点击车机排查输入车牌号，查询出车机厂商，联系车机厂商，询问车机异常问题
            </div>
            <div class="sub-title text">货车轨迹查不出来？</div>
            <div class="text">​1.请确认车机正常</div>
            <div class="text">2.轨迹可能那天司机没有跑</div>
            <div class="sub-title text">车机是关着的​货车轨迹一条直线</div>
            <div class="text">​1.可能车机流量卡松动</div>
            <div class="text">2.可能车机被关闭</div>
            <div class="text">3.可能各种异常导致未上报位置</div>
            <div class="sub-title text">​货车定位原理​</div>
            <div class="text">
                货车上有车机，每30秒上报一次，我们会汇总位置，显示到地图上。轨迹是车机上报位置的集合。
            </div>
        </div>
        <template #footer>
            <el-row justify="flex-end">
                <el-button type="primary" size="small" @click="close">
                    我知道了
                </el-button>
            </el-row>
        </template>
    </el-dialog>
</template>
<script>
export default {
    name: "NoTruckTips",
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
    },
};
</script>
<style lang="stylus">
.service-agreement-dialog{
    background: #ffffff;
    box-shadow: -15px 15px 150px  #666666;
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        padding-top: 8px;
        padding-bottom: 0;
    }
    .el-dialog__footer{
        padding-bottom: 16px
    }
    .agreement-content{
        margin-top: 16px;
        max-height:80vh;
        overflow: auto;
        color:#333;
        padding: 30px;
        box-sizing: border-box;
        div,ul{
            margin-top: 4px;
        }
        ul{
            list-style :square inside;
        }
        .title{
            text-align: center;
            margin-bottom: 8px;
        }
        .sub-title{
            font-size: 14px;
            font-weight: bold;
            margin: 8px 0;
        }
        .text{
            text-indent: 2em;
            line-height:22px;
        }
        .danger{
            color: #F56C6C;
        }
    }
}
</style>
