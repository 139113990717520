<template>
    <el-dialog
        top="2vh"
        :show-close="false"
        :modal="false"
        :header="null"
        :visible="visible"
        @close="close"
        custom-class="service-agreement-dialog"
        width="600px"
    >
        <!-- <div>查询前请仔细阅读《放舱网集卡定位功能线上服务协议》，并规范您的查车行为，若检测到违规，将会禁止您使用本功能</div> -->
        <div class="agreement-content">
            <div
                class="agreement-content-title"
            >
                放舱网集卡定位功能线上服务协议
            </div>
            <div class="text danger" style="margin-bottom: 10px">
                查询前请仔细阅读《放舱网集卡定位功能线上服务协议》，并规范您的查车行为，若检测到违规，将会禁止您使用本功能
            </div>
            <div class="text">
                宁波火箭数据科技有限公司（以下简称“乙方”）通过其数据查询平台（网址：http://www.fangcangwang.com/）提供数据查询服务，为获得乙方所提供的数据查询服务，用户（以下简称“甲方”）自愿通过在乙方的数据查询平台（网址：http://www.fangcangwang.com/）注册本协议内容覆盖乙方数据查询平台内的“集卡定位”所有数据查询服务产品（下称“产品”）。
            </div>
            <div class="text">
                甲方在首次进入“集卡定位”数据查询平台的任何产品页面时，本协议均会被主动展示，乙方提示甲方务必审慎阅读、充分理解本协议的所有内容，甲方（包括甲方企业下的员工账号）在数据查询平台点击“同意”按钮即表示甲方已充分阅读并完全同意、接受本协议的所有内容，甲方点击“同意”按钮后，方可使用乙方提供的产品服务。甲方如不同意，应点击“拒绝”按钮，甲方点击“拒绝”按钮后，将不能使用乙方的数据查询平台和服务。
                本协议在甲方点击“同意”按钮时生效，对甲乙双方产生法律约束力，其法律效力与纸质协议相同，在甲方企业下的员工账号点击“同意”按钮，乙方视为该员工被甲方授权签署本协议。本协议签署后，甲方如需纸质协议留档，可联系乙方客服人员索取，如未提出，乙方不主动提供纸质协议。本协议在宁波市鄞州区签订。
                乙方将如实记录甲方在乙方数据查询平台上的所有操作记录（包括点击协议“同意”、“拒绝”按钮的账户名和操作时间、甲方管理员批准账户加入甲方企业的时间和账户名、各数据查询服务产品项下的操作记录等）。
            </div>
            <div class="sub-title text">一、服务内容：</div>
            <div class="text text">
                甲方需在乙方数据查询平台上注册，输入车牌号可使用乙方提供的集卡定位数据查询服务等功能。甲方需要确保查询集卡定位或货车轨迹前征得车主书面同意，提前和车主沟通以免出现侵权情况，如您未征得车主同意，擅自查询了集卡定位或货车轨迹，由此带来的法律责任和风险将由您自行承担。
            </div>
            <div class="text">
                甲方在乙方放舱网查询平台上添加提单号后，甲方点击跳转至乙方集卡定位数据查询平台，并使用乙方提供的集卡定位数据查询服务等功能。甲方需要确保提单号的真实性，乙方会对甲方业务真实性进行抽查验证。
            </div>
            <div class="sub-title text">二、甲方的权利义务：</div>
            <div class="text">
                1、甲方在乙方数据查询平台上进行账号注册，完成认证后，方可使用乙方提供的服务。
            </div>
            <div class="text">
                2、甲方应妥善保管所有用户名和密码，不得公开或告知任何第三方（包括但不限于第三人、甲方内部未经授权的员工、乙方员工等）。如甲方知悉用户名和密码已经失密或可能已经失密时，应当立即书面通知乙方及有关各方，并立即终止该用户名和密码的使用。甲方未经乙方许可不得擅自将用户名和密码出租、出借、转让、赠与或者以任何方式交给任何第三方使用，一经乙方发现，乙方可立即终止提供一切服务并解除本协议，由此产生的所有后果和责任由甲方承担。甲方自行负责员工账号的批准和加入，应妥善管理注册于乙方平台上的甲方企业下所有账号的登录、使用和密码保护，如有员工离职或不被授权等无权操作帐号的情形，应及时对其账号进行退出处理，如需操作帮助，可咨询乙方。甲方企业下的所有账号的登录、操作人员均视为被甲方授权登录和操作的人员，在账号下的所有操作行为均视为甲方企业行为。
            </div>
            <div class="text">
                3、甲方应当遵守乙方在数据查询平台上发布的操作指导文件，甲方一经在乙方的数据查询平台（网址：http://www.fangcangwang.com/）点击“同意”按钮即视为甲方已充分阅读并同意乙方发布的相关操作指导文件。甲方不得非法使用乙方的数据查询平台，不得从事损害乙方或其他第三方利益的行为。因甲方不当使用乙方的数据查询平台所造成的一切损失由甲方承担（包括但不限于政府有关部门的罚款、对第三方的违约、侵权赔偿、商业信誉的损失、乙方因维护自身合法权益而支出律师费、取证费、诉讼费、保全费等损失）。
            </div>
            <div class="text">
                4、甲方有责任防止乙方数据查询平台受到非法入侵、非法传输及遭受其他可能影响到正常运行的妨碍，并保护业务记录和数据信息不受非法侵入、篡改或毁损。
            </div>
            <div class="text">
                5、甲方不得查询与货物信息无关的信息，如非法文件、木马程序、病毒程序等。
            </div>
            <div class="text">
                6、甲方不得利用乙方服务系统的漏洞、故障进行任何不正当的操作，不得损害乙方或第三方的利益。
            </div>
            <div class="text">
                7、甲方不得对乙方提供的数据查询平台进行反向工程操作，不得非法复制、模仿乙方的数据查询平台，否则因此给乙方造成的一切直接或间接损失由甲方承担（包括取证费、律师费等）。
            </div>
            <div class="text">
                8、甲方在使用乙方提供的集卡定位服务的同时，点击“同意”按钮即为甲方无条件授权并同意接受乙方将甲方名下拥有或挂靠的车辆的信息（当前位置、历史轨迹和其他信息）向平台上其他用户开放。如果甲方认证成为企业用户，甲方在使用乙方提供的集卡定位服务的同时，点击“同意”按钮即为甲方无条件授权并同意接受乙方将认证企业名下拥有或挂靠的车辆的信息（当前位置、历史轨迹和其他信息）向平台上其他用户开放。
            </div>
            <div class="text">
                9、在使用乙方提供的集卡定位服务时，甲方需确保在查询集卡定位或货车轨迹前已征得车主和驾驶员的书面同意，确保不出现侵权的情况；另外，在使用乙方提供的“精准查车“定位服务时，甲方应确保该提单号对应业务与查询车辆存在真实的运输关系。如甲方未征得车主和驾驶员的书面同意，擅自查询了集卡定位或货车轨迹，由此带来的所有法律责任和风险将由甲方自行承担，乙方不承担任何责任，如因此导致乙方遭受损失（包括但不限于第三方索赔、政府处罚、商誉损失、律师费、取证费、诉讼费、保全费等直接、间接损失），甲方应承担全部的赔偿责任。乙方将不定期抽查验证甲方的查询行为是否合规，如发现违规，乙方有权随时禁止甲方使用集卡定位功能，并保留追究甲方的法律责任。
            </div>
            <div class="sub-title text">三、乙方的权利义务</div>
            <div class="text">
                1、甲方利用乙方提供的数据查询平台查询集卡定位信息，乙方应及时显示集卡定位、货车行驶轨迹等有关信息，乙方有权依据该项服务向甲方收取相关费用。
            </div>
            <div class="text">
                2、乙方仅作为信息查询平台为甲方提供集卡定位的数据查询。对于甲方填写的提单号、船名、车牌号等信息的真实性、准确性、完整性、合法性等，乙方没有审核的能力和义务，也不承担任何法律责任。甲方自行对其填报的信息承担全部的法律责任。乙方有权随时要求甲方提供查询授权的证明文书及业务真实性证明。如有不符，乙方有权拒绝发送或单方面终止本协议。
            </div>
            <div class="text">
                3、乙方有权维护自身网络系统的安全，并在必要的情况下要求甲方对电子签名予以认证。
            </div>
            <div class="text">
                4、乙方在协议有效期内有义务保存甲方在数据查询平台往来的数据电文、传输记录等。
            </div>
            <div class="text">
                5、乙方发现甲方违反本协议约定，或者甲方有违背业务或信息安全或个人信息保护、非法使用网络平台或非法传输的行为，或者乙方有理由相信甲方的任何数据、信息（或第三方信息）违反了任何适用法律法规或者侵犯任何第三方的权利，或者乙方认为甲方继续使用乙方的数据查询服务会对乙方、其它用户或第三方造成严重损害或违反法律规定，则乙方有权要求甲方删除此类数据、信息（或第三方信息）、纠正违约违法行为、停止侵害行为。甲方在收到乙方的通知后应立即遵守此类请求，若未遵守，乙方有权终止本合同，终止甲方对乙方数据发送平台的使用、查询权限，乙方对甲方因此遭受的损失不承担任何责任。因此给乙方或者第三方造成的一切损失（包括但不限于政府有关部门的处罚、对第三方的赔偿、商誉损失、律师费、取证费、诉讼费、保全费等），甲方需予以赔偿。
            </div>
            <div class="text">
                6、乙方应保证数据查询平台和网络设备的正常工作。如出现有且不限于数据源方系统宕机、电信服务失效、网络攻击甚至数据源方因其他原因主动断供等非属于乙方原因造成的数据断供情况，乙方应及时通过网站发布相关的信息告知甲方，并在故障排除后再次通过网站发布相关的信息来通知甲方。乙方承诺将尽力修复故障或敦促数据源方恢复提供数据。但不承担数据断供停止服务期间造成甲方直接或间接经济损失及相关责任。
            </div>
            <div class="text">
                7、甲方应保证账户中金额充足，在甲方未支付应付费用或账户欠费、余额不足的情况下，乙方有权中止一切服务，由此造成的一切不利后果由甲方自行承担，乙方不承担任何责任。
            </div>
            <div class="text">
                8、对于甲方合理的请求，乙方原则上不收取费用，但对多次重复、超出合理限度的请求，乙方将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，乙方可能会予以拒绝。
            </div>
            <div class="text">
                9、在以下情形中，按照法律法规要求，乙方将无法响应甲方的请求
            </div>
            <ul style="margin-left: 2em">
                <li>与国家安全、国防安全直接相关的</li>
                <li>与公共安全、公共卫生、重大公共利益直接相关的</li>
                <li>与犯罪侦查、起诉、审判和判决执行等直接相关的</li>
                <li>有充分证据表明甲方存在主观恶意或滥用权利的</li>
                <li>
                    响应甲方的请求将导致甲方或其他个人、组织的合法权益受到损害的
                </li>
                <li>涉及商业秘密的</li>
            </ul>
            <div class="sub-title text">四、服务条款与费用结算</div>
            <div class="text">1、费用结算</div>
            <ul style="margin-left: 2em">
                <li>货车轨迹查询：1火币1车/次。</li>
            </ul>
            <div class="text">
                以上费用仅包含乙方数据查询平台的数据查询费。若产生其他费用，由甲方自行承担。
            </div>
            <div class="sub-title text">五、违约责任</div>
            <div class="text">
                1、甲方因违反本协议约定或法定的义务，给乙方造成的损失（包括但不限于政府处罚、第三方索赔、利润损失、商誉损失），以及乙方为维护自身权益而支出的律师费、公证费、调查取证费、诉讼费等，由甲方承担赔偿责任。
            </div>
            <div class="text">
                2、除本协议另有约定外，若甲方违反本协议或法律规定，乙方有权中止向甲方提供服务，由此造成的一切不利后果由甲方自行承担，乙方不承担任何责任，若经乙方通知后30日内，甲方仍未纠正、补救其违约、违法行为，则乙方有权解除本合同，要求甲方赔偿损失，甲方的赔偿范围以本协议第5.1条约定为准
            </div>
            <div class="sub-title text">六、保密和知识产权条款</div>
            <div class="text">
                1、保密信息：甲乙双方均承诺并保证不向第三方泄露本协议内容以及履行协议过程中涉及到保密信息。该保密信息指由一方向对方透露的，与双方合作有关的任何口头或书面形式或其他形式的不公开信息，包括但不限于商业计划、客户名单、客户数据、订单数据、报价与价格、技术数据、产品构思、开发计划、职员名单、操作手册、加工工艺、技术理论、发明创造、财务情况、平台内容、运作模式、业务信息和其他递交时约定为保密信息的资料（以下通称“保密信息”）。未经披露方书面同意，另一方不得将披露方的保密信息泄露、公开、复制、用于履行本协议之外的用途。
            </div>
            <div class="text">
                2、保密期限：本协议所涉及保密信息保密期限为永久保密。本协议合作期限内及本协议终止或者解除后，甲、乙双方承诺不向任何第三方（包括但不限于与对方构成商业竞争关系的企业、商业机构或者组织、个人）提供有关双方业务、技术等一切相关信息或者资料，否则由泄密一方承担相应的责任，并赔偿另一方因此造成的一切损失。
            </div>
            <div class="text">
                3、乙方数据查询平台和服务的所有权和所涉及的所有知识产权（包括但不限于著作权、商标权、商业秘密等权利）均归属于乙方。甲方无权对该系统及服务主张权利，不得以任何形式侵犯乙方的所有权和知识产权。
            </div>
            <div class="text">
                4、未经乙方书面许可，甲方不得将乙方数据查询平台或其任何部分复制、发布、泄露、擅自开发、许可他人利用、出售、转售。
            </div>
            <div class="text">
                5、甲方不得复制、模仿乙方数据查询平台的设计、系统界面、文字、功能和图表等一切关于乙方数据查询平台的信息和资料。
            </div>
            <div class="text">
                6、甲方不得基于乙方数据查询平台或数据传输服务进行修改、解密、反汇编、反编译、反向工程、拷贝、翻译或制作衍生作品。
            </div>
            <div class="text">
                7、乙方的名称、标志、LOGO、乙方数据发送平台的名称、产品都属于乙方所有，未经乙方书面授权，甲方及任何第三方不得使用。
            </div>
            <div class="sub-title text">七、免责条款</div>
            <div class="text">
                鉴于互联网所具有之特殊性质，乙方对黑客攻击、网络病毒、电信部门技术调整、检修及线路故障导致之影响、因政府管制而造成的暂时性关闭、电力系统故障或限制性供电等非乙方所能控制的其他任何影响网络正常经营之情形不承担责任。
            </div>
            <div class="sub-title text">八、不可抗力</div>
            <div class="text">
                1、甲乙任何一方在履行本协议规定的义务时，当发生不可抗力之因素（包括但不限于发生地震、水灾、旱灾等自然灾害、战争、罢工、政府禁令、电信线路出现故障、电力系统故障等），因立即通知对方，并尽力采取补救错施。在此期间，数据发生迟延、脱漏或错误及引起的风险和责任，合同双方均不予承担。
            </div>
            <div class="text">
                2、因电信部门检修或国家政策调整等非甲乙双方所能控制的其他原因造成系统暂时停止服务原因造成服务中断，双方互不承担责任。
            </div>
            <div class="sub-title text">九、争议的解决</div>
            <div class="text">
                本合同之签署、效力、解释和执行均应适用中华人民共和国法律。对于因本合同的解释及执行而产生之争议，双方通过友好协商解决；协商不成时，任何一方均可将有关争议提交乙方所在地人民法院解决。
            </div>
            <div class="sub-title text">十、其他</div>
            <div class="text">
                1、本协议自甲方在乙方数据查询平台点击“同意”按钮时生效。本协议的任何条款如因违反法律效力性规定而被人民法院确认无效的，不影响其他条款效力。
            </div>
            <div class="text">
                2、因合同一方违反本协议约定，但尚未造成严重后果的，另一方可以中止本协议的履行，直到违约方做出相应的保证和做出相应的整改后，另一方重新履行合同或提供相应的服务。
            </div>
            <div class="text">
                3、本合同生效后，乙方有权就相关条款进行变更或者补充，甲方如继续使用乙方的数据发送平台，则视为甲方同意该等变更或补充；甲方如不同意该等变更或补充，应立即书面通知乙方终止本合同并停止使用乙方的数据发送平台，乙方收到甲方的终止合同通知书之日本合同终止，对于甲方账户中尚未使用的服务费乙方无息返还给甲方，双方无需就本协议终止向对方承担责任。
            </div>
            <div class="text">
                4、本协议有效期为一年。在有效期届满前30天内，如任何一方未向对方书面提出到期终止本协议的要求，则本协议自动展期一年，以此类推，上述展期不受次数限制。
            </div>
        </div>
        <template #footer>
            <el-row justify="flex-end">
                <el-button type="primary" size="small" @click="close">
                    我知道了
                </el-button>
            </el-row>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "ServiceAgreement",
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
    },
};
</script>

<style lang="stylus">
.service-agreement-dialog{
    background: #ffffff;
    box-shadow: -15px 15px 150px  #666666;
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        padding-top: 8px;
        padding-bottom: 0;
    }
    .el-dialog__footer{
        padding-bottom: 16px
    }
    .agreement-content{
        margin-top: 16px;
        max-height:80vh;
        overflow: auto;
        color:#333;
        padding: 30px;
        box-sizing: border-box;
        div,ul{
            margin-top: 4px;
        }
        ul{
            list-style :square inside;
        }
        &-title{
            text-align: center;
            font-size: 24px; 
            font-weight: bold; 
            margin-bottom: 40px
        }
        .sub-title{
            font-size: 14px;
            font-weight: bold;
            margin: 8px 0;
        }
        .text{
            text-indent: 2em;
            line-height:22px;
        }
        .danger{
            color: #F56C6C;
        }
    }
}
</style>
